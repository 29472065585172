/**
 * Created by MosNeuro on 18.03.2017.
 */
import {Injectable} from '@angular/core';


@Injectable()
export class ServerString{

    // server="localhost";
    // protocol="https";
    // port="44327";

    constructor(){ }

    fullServerString()
    {
     return "https://"+this.clientStringPrimery().replace(":3000/", "")+":44327/";

       // return "http://"+this.clientStringPrimery().replace("3000", "44327")+":5000/";
    }

    fullServerStringAdditional()
    {
     return "https://"+this.clientStringAdditional().replace(":3000/", "")+":44327/";

       // return "http://"+this.clientStringPrimery().replace("3000", "44327")+":5000/";
    }

    wsServerString()
    {
      return "ws://"+this.clientStringPrimery().replace(":3000/", "")+":44327/";
    }


    convertLink()
    {
        return this.fullServerString()+'convert';
    }

    clientString(): string
    {
        return this.clientStringPrimery()+"/";
    }

    clientString2(): string
    {
        return this.clientStringPrimery()+"/";
    }

    clientStringPrimery(): string
    {

    // return "mn0.theiwish.ru";
        //return "office.iqvision.pro";
        //return "91.244.253.112";
		 //return "ab.mosta.pro";
  //  return "test17-6.theiwish.ru";
     return "mn0.theiwish.ru";
      //  return "localhost";//:54327";
    // return "mn0.theiwish.ru";
     //return "mn0d.theiwish.ru";
  //   re//turn "test4321.theiwish.ru";
    //    return "test18-9.theiwish.ru";
        //    return "test16-4.theiwish.ru";
      //  return "mn0.theiwish.ru";
        //return "mosta3.theiwish.ru/";
        //     return "92.255.193.40:3381/";
//return "localhost";


        //return "mn0d.theiwish.ru";
    }


    clientStringAdditional(): string
    {
		 return "mn0.theiwish.ru";
    //  return "test17-6.theiwish.ru";
    // return "test18-9.theiwish.ru";
    }


}
